var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main_wrapper"},[_c('main',{staticClass:"content_wrapper"},[_c('div',{staticClass:"inner"},[_vm._m(0),_c('section',{staticClass:"content_pannel"},[_vm._m(1),_c('div',{staticClass:"content_inner"},[_c('div',{staticClass:"grid grid_pad grid_wrap"},[_c('div',{staticClass:"grid_col grid_col_1_1 form_block"},[(_vm.url)?_c('VityTable',{attrs:{"columns":_vm.columns,"url":_vm.url+'showPremiums',"params":{
                                    admin:_vm.$store.state.user,
                                },"swipeResult":{
                                    column: [2],
                                    data:{
                                        '1': 'Hora Cortesia',
                                        '3': 'Mensual',
                                        '4': 'Anual'
                                    }
                                },"tableFooter":""}}):_vm._e()],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page_header"},[_c('h2',[_vm._v("Usuarios Premium")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"pannel_header"},[_c('h3',[_vm._v("Listado de usuarios premium")])])}]

export { render, staticRenderFns }