<template>
    <div class="main_wrapper">
        <main class="content_wrapper">
            <div class="inner">
                <section class="page_header">
                    <h2>Usuarios Premium</h2>
                </section>
                <section class="content_pannel">
                    <header class="pannel_header">
                        <h3>Listado de usuarios premium</h3>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_1 form_block">
                                <VityTable 
                                    v-if="url"
                                    :columns="columns"
                                    :url="url+'showPremiums'"
                                    :params="{
                                        admin:$store.state.user,
                                    }"
                                    :swipeResult="{
                                        column: [2],
                                        data:{
                                            '1': 'Hora Cortesia',
                                            '3': 'Mensual',
                                            '4': 'Anual'
                                        }
                                    }"
                                    tableFooter
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios'
import VityTable from '@/components/VityUtils/vitytables/VityTables.vue';

export default {
    name: 'Usuarios',
    components:{
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'Email',
                    dbName: 'email',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Premium hasta',
                    dbName: 'deleted_at',
                    orderable: true,
                    searchable: true,
                    date: true
                }, {
                    name: 'Tipo',
                    dbName: 'type',
                    orderable: true,
                    searchable: true
                }, {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                }
            ],
            url: process.env.VUE_APP_URL,
            url_imgdb: process.env.VUE_APP_URL_IMGDB,
            user: null
        }
    },
    methods: {
        init: function(){
            if(this.$route.params.id)
                this.load(this.$route.params.id);
            else
                this.user = {}
        },
        load: function(id){
            axios
                .get(process.env.VUE_APP_URL+`users/${id}`, {
                    params: {
                        admin: this.$store.state.user,
                        premiums: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.user = rs.data;
                        this.user.created_at = this.user.created_at.replace(' ', 'T');
                        this.user.created_at = this.user.created_at.replace(/\.[0]+Z/gm, '');
                        this.user.first_login = this.user.first_login.replace(' ', 'T');
                        this.user.first_login = this.user.first_login.replace(/\.[0]+Z/gm, '');
                        this.user.updated_at = this.user.updated_at.replace(' ', 'T');
                        this.user.updated_at = this.user.updated_at.replace(/\.[0]+Z/gm, '');

                        this.user.premiums.forEach(element => {
                            element.created_at = element.created_at.replace(' ', 'T');
                            element.created_at = element.created_at.replace(/\.[0]+Z/gm, '');
                            element.updated_at = element.updated_at.replace(' ', 'T');
                            element.updated_at = element.updated_at.replace(/\.[0]+Z/gm, '');
                            if(element.deleted_at){
                                element.deleted_at = element.deleted_at.replace(' ', 'T');
                                element.deleted_at = element.deleted_at.replace(/\.[0]+Z/gm, '');
                            }
                        });

                        this.$parent.modal = true;
                    }
                })
        },
        save: function(){
            if(!this.user.email){
                this.$parent.error = {type: 3, msg: 'Faltan campos por rellenar'};
                return;
            }

            axios
                .post(process.env.VUE_APP_URL+`users`+(this.user.id ? `/${this.user.id}` : ''), {
                    admin: this.$store.state.user,
                    email: this.user.email,
                    premiums: this.user.premiums
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        if(this.user.id){
                            this.$parent.error = {type: 1, msg: 'Guardado correctamente'};
                            this.init();
                        }
                        else
                            this.$router.push(`/usuarios/${rs.data}`);
                    }
                    else
                        this.$parent.error = {type: 2, msg: rs.msg};
                })
                .catch(error => {
                    this.$parent.error = {type: 2, msg: 'Fallo al conectar'};
                })
        },
        close: function(){
            this.$parent.modal = false;
            this.user = null;
            this.$router.push('/usuarios');
        },
        nuevo: function(){
            this.user = {premiums: []};
            this.$parent.modal = true;
        },
        getTipo: function(tipo){
            switch(tipo){
                case 1:
                    return "Hora Cortesia"
                case 2:
                    return "48 Horas imagen"
                case 3:
                    return "Mensual"
                case 4:
                    return "Anual"
                default:
                    return "No Definido"
            }
        }
    },
    created: function(){
        this.init();
    },
    watch: {
        $route: function(){
            this.init();
        }
    }
}
</script>